<template>
  <div>
    Device share link:<br>
    <input v-model="text">
  </div>
</template>

<script>

export default {
  name: "PageSettings",
  components: {}
}
</script>

<style scoped>

</style>