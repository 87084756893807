import {createApp} from 'vue/dist/vue.esm-bundler.js'
import App from './App.vue'
import './assets/tailwind.css'
import {createRouter, createWebHistory} from "vue-router";
import PageNavigation from "@/pages/PageNavigation";
import PageSearch from "@/pages/PageSearch";
import PageInventory from "@/pages/PageInventory";
import PageSettings from "@/pages/PageSettings";
//import PageBilling from "@/pages/PageBilling";
//import PageAccount from "./pages/PageAccount";
import PageLogin from "./pages/PageLogin";
import Axios from 'axios';
import store from './store/store';
import PageForgotPassword from "./pages/PageForgotPassword";
import PageDevice from "@/pages/PageDevice";
import PageCustomerList from "./pages/PageCustomerList";
import ModalEditCustomerEmail from "./components/customer/ModalEditCustomerEmail";
import ModalEditCustomerRef from "./components/customer/ModalEditCustomerRef";
import ModalNewCustomer from "./components/customer/ModalNewCustomer";
import ModalAddPerimission from "./components/tracker/ModalAddPerimission";
import ModalEditCustomerPassword from "./components/customer/ModalEditCustomerPassword";

const app = createApp(App);

// set auth header
Axios.defaults.headers.common['Authorization'] = `Bearer ${store.state.token}`;

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            component: PageNavigation,
            redirect: '/inventory',
            children: [
                {
                    path: 'search',
                    component: PageSearch
                },{
                    path: 'inventory',
                    component: PageInventory,
                },
                {
                    path: 'settings',
                    component: PageSettings,
                },
                /*
                {
                    path: 'billing',
                    component: PageBilling,
                },
                */
                {
                    path: 'device/:imei',
                    component: PageDevice,
                },
                {
                    path: 'customer',
                    component: PageCustomerList,
                },
                {
                    path: 'customer/editMail/:id',
                    component: ModalEditCustomerEmail,
                },
                {
                    path: 'customer/editRef/:id',
                    component: ModalEditCustomerRef,
                },
                {
                    path: 'customer/editPassword/:id',
                    component: ModalEditCustomerPassword,
                },
                {
                    path: 'customer/new',
                    component: ModalNewCustomer
                },
                {
                    path: 'device/permission/:imei',
                    component: ModalAddPerimission,
                },

            ]
        },
        {
            path: '/login',
            component: PageLogin
        },
        {
            path: '/forgotpassword',
            component: PageForgotPassword
        },
    ]
});

router.beforeEach((to, from, next) => {

    if(to.path !== "/login") {
        if(store.getters.getToken !== undefined) {
            if(store.getters.getToken.length === 0) {
                console.log("requiring login..")
                next("/login")
            }
        } else {
            next("/login")
        }
    }
    next();
})

app.use(router);
app.use(store);
app.mount('#app');


