<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <!--
    This example requires updating your template:

    ```
    <html class="h-full">
    <body class="h-full">
    ```
  -->
  <div class="min-h-full">
    <Disclosure as="nav" class="bg-white border-b border-gray-200" v-slot="{ open }">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex justify-between h-16">
          <div class="flex">
            <div class="flex-shrink-0 flex items-center">

              <img class="mx-auto h-16 w-auto bg-blend-overlay" :src="require('@/img/sr2_vektor.svg')" alt="Stadt_Ritter_Logo" />
            </div>
            <div class="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
              <a v-for="item in navigation" :key="item.name" @click="route(item.path)" :class="[item.current ? 'border-red-500 text-gray-900 cursor-pointer' : 'cursor-pointer border-transparent text-gray-500 hover:border-black hover:text-gray-700', 'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium']" :aria-current="item.current ? 'page' : undefined">
                {{ item.name }}
              </a>
            </div>
          </div>
          <div>
            <button v-if="this.$store.getters.isLoggedIn !== ''" @click="logout()" type="button" value="logout" class=" mt-4 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
              Logout
            </button>
            <button v-if="this.$store.getters.isLoggedIn === ''" type="button" @click="this.$router.push('/login')" class=" mt-4 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"> Login </button>
          </div>
          <div class="-mr-2 flex items-center sm:hidden">
            <!-- Mobile menu button -->
            <DisclosureButton class="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <span class="sr-only">Open main menu</span>
              <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
              <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
            </DisclosureButton>
          </div>
        </div>
      </div>

      <DisclosurePanel class="sm:hidden">
        <div class="pt-2 pb-3 space-y-1">
          <DisclosureButton v-for="item in navigation" :key="item.name" as="a" :href="item.href" :class="[item.current ? 'bg-indigo-50 border-indigo-500 text-indigo-700' : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800', 'block pl-3 pr-4 py-2 border-l-4 text-base font-medium']" :aria-current="item.current ? 'page' : undefined">
            {{ item.name }}
          </DisclosureButton>
        </div>
        <div class="pt-4 pb-3 border-t border-gray-200">
          <div class="flex items-center px-4">
            <div class="flex-shrink-0">
              <img class="h-10 w-10 rounded-full" :src="user.imageUrl" alt="" />
            </div>
            <div class="ml-3">
              <div class="text-base font-medium text-gray-800">{{ user.name }}</div>
              <div class="text-sm font-medium text-gray-500">{{ user.email }}</div>
            </div>
            <button type="button" class="ml-auto bg-white flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <span class="sr-only">View notifications</span>
              <BellIcon class="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div class="mt-3 space-y-1">
            <DisclosureButton v-for="item in userNavigation" :key="item.name" as="a" :href="item.href" class="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100">
              {{ item.name }}
            </DisclosureButton>
          </div>
        </div>
      </DisclosurePanel>
    </Disclosure>

      <main>
        <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <router-view></router-view>
        </div>
      </main>
  </div>
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/vue/outline'

const navigation = [
  //{ name: 'Suche', path: '/search', current: true },
  { name: 'Inventar', path: '/inventory', current: true },
  //{ name: 'Abrechnung', path: '/billing', current: false },
  //{ name: 'Tracker', path: '/device', current: false },
  { name: 'Kunden', path: '/customer', current: false},
  //{ name: 'Login', path: '/login', current: false },
  
  //{ name: 'Settings', path: '/settings', current: true },
]

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    BellIcon,
    MenuIcon,
    XIcon,
  },
  data() {
    return {
      navigation
    }
  },
  methods: {
    route(path) {
      this.$router.push(path);
      for(let i = 0; i < this.navigation.length; i++) {
        this.navigation[i].current = false;
      }
      console.log('PageNavigation route navigation',this.navigation);
      if(this.navigation[this.navigation.findIndex(nav => nav.path === path)]){
        this.navigation[this.navigation.findIndex(nav => nav.path === path)].current = true;
      }else{
        this.navigation[0].current = true;
      }
    },
    logout(){
      try{
        this.$store.dispatch('logout');
        localStorage.clear();
        this.$router.push('/login');
      } catch {
        console.log('Failed Logout');
      }
    }

  },
  computed: {
    currentPage() {
      return this.navigation.find(o => o.current === true).name;
    }
  },
  mounted() {
    console.log('PageNavigation mounted this.$router.currentRoute', this.$router.currentRoute.value.path.split('/')[1]);
    this.navigation.forEach(e => e.current = false);
    //this.navigation.find(e => e.path === ('/' + this.$router.currentRoute.value.path.split('/')[1])).current = true;
  }
}
</script>