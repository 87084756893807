import axios from 'axios';
//const url = 'https://startdev.finder-portal.com/wp-json/jwt-auth/v1/token';
const url = 'https://start.finder-portal.com/wp-json/jwt-auth/v1/token';

let configLogin = {
    "headers": {
        "Content-Type": "application/x-www-form-urlencoded",
        "Accept": "*/*"
    },
    // "transformRequest": [(data, headers) => {
    //     delete headers.common.Authorization
    //     return new URLSearchParams(data).toString();
    // }]
}
export default {
    login(credentials) {
        return axios.post(url, credentials, configLogin)
            .then(response => response.data.data);
    },
    /*signUp(credentials) {
        return axios
            .post(url + 'sign-up/', credentials)
            .then(response => response.data);
    },*/
    /*getSecretContent() {
        return axios.get(url + 'secret-route/').then(response => response.data);
    }*/
};