<template>
  <div class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <img class="mx-auto h-20 w-auto" :src="require('@/img/logo_main.png')" alt="Stadt Ritter Logo" />
        <h2 class="mt-6 text-center text-3xl font-bold text-srDarkGray">
          Willkommen
        </h2>

      </div>

      <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form class="space-y-6" method="POST" action="#" > <!-- -->
            <div>
              <label for="email" class="block text-sm font-medium text-srDarkGray">
                E-Mail-Addresse
              </label>
              <div class="mt-1">
                <input v-model="username" id="email" name="email" type="email" autocomplete="email" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm" />
              </div>
            </div>

            <div>
              <label for="password" class="block text-sm font-medium text-srDarkGray">
                Passwort
              </label>
              <div class="mt-1">
                <input v-model="password" id="password" name="password" type="password" autocomplete="current-password" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm" />
              </div>
            </div>

            <div class="flex items-center justify-start">
<!--              <div class="flex items-center">
                <input id="remember-me" name="remember-me" type="checkbox" class="h-4 w-4 text-gray-600 focus:ring-gray-500 border-gray-300 rounded" />
                <label for="remember-me" class="ml-2 block text-sm text-gray-900">
                  Login merken
                </label>
              </div>-->

              <div class="text-sm">
                <a href="/forgotpassword" class="font-medium hover:text-srDarkRed text-srRed">
                  Passwort vergessen?
                </a>
              </div>
            </div>

            <div>
              <button  @click="doLogin()" type="button" value="Login1" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500" :class="mainButtonStyle">
                {{mainButtonText}}<Spinner v-if="loggingIn" color="#FFFFFF" class="h-5 w-5 ml-2"/>
              </button>
              <p v-if="msg" v-html="msg" style="padding-top:10px;"></p>
            </div>
          </form>

          <div class="mt-6">
            <div class="relative">
              <div class="absolute inset-0 flex items-center">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

</template>

<script>
import AuthService from '@/services/AuthService.js';
import {mapActions} from "vuex";
import Spinner from "@/components/util/Spinner";

const styleDefault = ["bg-gray-600", "hover:bg-gray-700"];
const styleLoggingIn = ["bg-gray-400", "hover:bg-gray-500"];

export default {
  name: "PageLogin",
  components: {Spinner},
  data(){
    return {
      username: "",
      password: "",
      msg: "",
      loggingIn: false
    };
  },
  computed: {
    mainButtonStyle() {
      return this.loggingIn ? styleLoggingIn : styleDefault;
    },
    mainButtonText() {
      return this.loggingIn ? "autorisiere.." : "Login";
    }
  },
  methods: {
    async doLogin() {
      if(!this.loggingIn) {
        this.loggingIn = true;
        this.msg = null;

        const credentials = {
            username: this.username,
            password: this.password
        };

        AuthService.login(credentials).then(resp => {
            console.log('Login response', resp);
            if(resp.token !== undefined && resp.email !== undefined) {
              this.login({token: resp.token, user: resp.email});
              this.$router.push('/inventory');
            } else {
              this.msg = "an error occurred"
              this.loggingIn = false;
            }
          }).catch((reason) => {
            console.log('reason', reason);
            
            if(reason.response.message !== undefined) {
              this.msg = reason.response.data.message
            } else {
              this.msg = reason;
            }
            
            this.loggingIn = false;
          });
      }
    },
    ...mapActions(["login"])
  }
};
</script>

<style scoped>

</style>