<template>
  <div>
  <div class="w-full flex items-center justify-between p-6 space-x-6">
    <div class="flex-1 truncate">
      <div class="flex justify-between space-x-3">
        <h3 class="text-gray-900 text-sm font-medium truncate"> {{ account.name }}</h3>
        <button type="button" v-on:click="deleteUser(account.ID)"><TrashIcon class="-ml-2 w-5 h-5 text-srRed hover:text-srDarkRed" aria-hidden="true"></TrashIcon></button>
      </div>
      <div class="flex items-center space-x-3">
      <p class="mt-1 text-gray-500 text-sm truncate">{{ account.email }}</p>
      <button type="button" @click="$router.push('/customer/editMail/'+account.ID)"> <PencilAltIcon class=" w-5 h-5 text-gray-400 hover:text-srDarkGray" aria-hidden="true" /></button>
      </div>
      <div class="flex items-center space-x-3">
        <p class="mt-1 text-gray-500 text-sm truncate">{{ getRefText }}</p>
        <button type="button"  @click="$router.push('/customer/editRef/'+account.ID)"> <PencilAltIcon class=" w-5 h-5 text-gray-400 hover:text-srDarkGray" aria-hidden="true" /></button>
      </div>
      <button type="button" @click="$router.push('/customer/editPassword/'+account.ID)" class="inline-flex items-center px-2.5 py-1.5 mt-2 border border-transparent text-sm leading-4 rounded-md shadow-sm text-gray-600 hover:text-white bg-srGray hover:bg-srDarkGray">
        Passwort zurücksetzen
      </button>
      <!--<p class="mt-1 text-gray-500 text-sm truncate">{{ account.ID }}</p>-->
    </div>
  </div>
  </div>
</template>

<script>

import { PencilAltIcon, TrashIcon } from '@heroicons/vue/solid'
import DataService from "../../services/DataService";

export default {
  name: "Account",
  props: {
    account: {
      required: true
    },
  },
  components: {
    PencilAltIcon,
    TrashIcon
  },
  computed: {
    getRefText(){
      if(this.account.ref === ''){
        return "Referenznummer noch nicht vergeben";
      }else {
        return this.account.ref;
      }
    }
  },
  methods: {
    deleteUser(id_user){
      let _router = this.$router;
      id_user = parseInt(id_user);
      DataService.deleteUser(id_user).then(function (data){
        console.log(data);
        //alert(data.msg);
        _router.go();
      }).catch(function(data){
        console.log(data);
        _router.go();
      });
      /*localStorage.removeItem('accounts');
      localStorage.removeItem('time_accounts');*/
      //location.reload();
    },
  },
}
</script>

<style scoped>

</style>